@import '../../../Styles/_theme.scss';
@import './../../../Styles/placeholder';
.btn {
    padding: 10px 20px;
    border-radius: $border-radius;
    color: $white;
    font-family: 'Poppins', sans-serif;
    font-size: $base;
    font-weight: $bolder;
    line-height: 1.29;
    letter-spacing: 0.2px;
    border: none;
    margin: 10px 10px;
    outline: none;
    cursor: pointer;
    &:hover {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
}

.primary {
    background-color: $green;
    font-size: 1.325rem;
    text-transform: uppercase;
    line-height: 2rem;
    font-weight: $bold;
    &:hover {
        background-color: $orange;
    }
}

.primary-invert {
    background-color: $white;
    color: $green;
    font-size: 1.125rem;
    text-transform: uppercase;
    line-height: 2rem;
    font-weight: $bold;
    border: 2px solid #d3d3d3;
}

.primary-red {
    color: $white;
    background-color: $primary-red;
}
.primary-green {
    color: $white;
    background-color: $green;
    &:hover {
        background-color: $orange;
    }
}

.secondary {
    background-color: $white;
    color: $primary-black;
    &:hover {
        background-color: $orange;
    }
}
.primary-gray {
    background-color: $gray;
    font-size: 1.325rem;
    text-transform: uppercase;
    line-height: 1.29;
    font-weight: $bold;
    &:hover {
        background-color: $orange;
    }
}
// .ghost {
//   border: solid 1.5px $orange;
//   background-color: $white;
//   color: $orange;
// }
