@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.privacy-policy {
  margin-bottom: 39px;
  margin-top: 81px;
  background-image: url(../../Assets/Images/cargo-fixed-design.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 125px);
  background-attachment: fixed;
  .container-privacy {
    max-width: 690px;
    margin: 0 auto;
    width: 100%;
    padding: 27px 30px;
    background: #fff;
  //  text-align: center;
    max-width: 690px;
    margin: 0 auto;
    background: #fff;
    margin-bottom: 0px;
    padding-bottom: 30px;
    height: calc(100vh - 135px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 1em;
    }
     
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px#fff;
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      outline: 1px solid #fff;
    }
    .heading{
        @extend %heading;
        margin-bottom: 1.5rem;
        line-height: 1.9rem;
        font-size: 30px;
        color: #2d2d2d;
        font-family: Poppins;       
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 19.95px;
        text-align: center;
        margin-top: 0px;
    }
    ol{
     li {
      margin-left: -28px;
  }
}
  }
  .text{
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.terms-conditions{
  .common-banner{
    margin-bottom: 50px;
  }
} 

@media (max-width: 767px) {

  .privacy-policy{
    margin-top: 94px;
    min-height: 100%;
    .container-privacy{
      height: auto;
      overflow-y: initial;
      padding: 27px 15px;
    }
  }

}
ul.leftd li {
  margin-left: 41px;
}