@import "../../Styles/theme";
@import "../../Styles/placeholder";

.header {
  padding: 0px 0px 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: all ease-in-out 200ms;
  
    background: #fff;
  .menu-icon {
    display: none;
  }
  .logo {
    width: 14.8125rem;
    a {
      outline: none;
    }
  }
  .navigation {
    transition: all ease-in-out 300ms;
    width: calc(100% - 14.8125rem);
    padding-left: 4.5rem;
    align-self: flex-end;
    .close-menu {
      display: none;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .menu {
      .menu-item {
        .cursor-pointer {
          cursor: pointer;
          .setting {
            display: inline-block;
            vertical-align: middle;
          }
          .profile-name {
            display: inline-block;
            vertical-align: middle;
            text-transform: capitalize;
            font-size: 15px;
          }
        }
        &.more-item {
          position: relative;
          .dropdown-menu-wrapper {
            display: none;
            opacity: 0;
            left: 50%;
            min-width: 248px;
            position: absolute;
            transform: translateX(-50%);
            transition: max-height 0.3s linear, opacity 0.3s linear;
            top: 32px;
            // &::after {
            //   bottom: -24px;
            //   content: "";
            //   display: block;
            //   height: 24px;
            //   position: absolute;
            //   width: 100%;
            // }
            .dropdown-menu {
              box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
              border-radius: 4px;
              overflow: hidden;
              &::after {
                background-color: $white;
                box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
                content: "";
                display: block;
                height: 12px;
                left: 50%;
                position: absolute;
                top: -7px;
                transform: rotate(45deg);
                width: 12px;
                z-index: -1;
              }
              .dropdown-menu-item {
                background-color: $white;
                .dropdown-item {
                  padding: 16px 24px;
                  display: block;
                  font-size: 1rem;
                  color: $primary-black;
                  font-weight: $boldest;
                  &:hover {
                    background-color: #f2f2f2;
                    border-color: transparent;
                  }
                }
              }
            }
          }
          &:hover {
            .dropdown-menu-wrapper {
              display: block;
              transition: max-height 0.3s linear, opacity 0.3s linear;
              opacity: 1;
            }
          }
        }
        .item {
         // text-transform: uppercase;
          font-size: 1.2rem;
          color: $primary-black;
          font-weight: $boldest;
          border-bottom: 1px solid #fff;
          display: flex;
          -webkit-tap-highlight-color: transparent;
          transition: none;
          margin: 0 1rem;
          padding: 0.5rem 0;
          &:hover {
            border-bottom: 1px solid $green;
          }
        }
        &.email {
          // margin-left: auto;
          .item {
            color: #0b0b0b;
          }
          .icon {
            width: 28px;
            height: 22px;
          }
        }
        .btn {
          // Todo mixin
          border-radius: 10px;
          .icon {
            margin-right: 10px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  &.bg-white {
    padding: 0 0;
    background-color: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 200ms;
    .navigation {
      .menu {
        .menu-item {
          &.email {
            .item {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.left-minus-100 {
  left: -120%;
}

.left-0 {
  left: 0;
}

.setting {
  cursor: pointer;
  margin-left: 30px;
  width: 20px;
  height: 21px;
}
.setting-menu {
  width: 300px;
  right: -120%;
  position: fixed;
  top: 0;
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
  transition: all 200ms ease-in-out;
  height: 100%;
  &.show {
    right: 0;
  }
  .toggle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    box-shadow: 0 0 4px 0 rgba(29, 29, 27, 0.15);
    padding: 5px 15px;
    .icon {
      cursor: pointer;
    }
    h5 {
      font-size: 14px;
    }
  }
  .profile,
  .logout {
    padding: 15px 15px;
    cursor: pointer;
    color: $black;
    &:hover {
      background-color: #dedede;
    }
  }
  .menu-on-small{
    display:none;
  }
  .menu-on-large{
    display:block;
  }
}

// New header

.header-top {
  background: #2d2d2d;
  height: 6px;
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 5px;
  margin-top: 0px;
  .header-top-inner {
    width: 100%;
    display: block;
    position: relative;
    .customer-login {
      position: relative;
      width: 187px;
      float: right;
      height: 35px;
      border-top: 35px solid black;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      
      .customer-toggle {
        font-size: 14px;
        margin: 0 auto;
        width: 187px;
        display: block;
        text-transform: capitalize;
        list-style: none;
        text-align: center;
        padding: 8px 0px;
        position: relative;
        left: -20px;
        top: -35px;
        .logincls {
          .btn {
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
      &.signup-bg{
        border-top-color: $green;
       
      }
    }
  }
}


// @media (max-width: 1920px) {
//   .header {
//     padding: 2rem 0;
//   }
// }

@media (max-width: 1070px) {
  .setting {
    margin-left: auto;
    margin-right: 10px;
  }

  .setting-menu{
    .menu-on-small{
      display:block;
    }
    .menu-on-large{
      display:none;
    }
  }
  .header {
    padding: 0;
    background-color: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.47);
    .ref {
      align-items: flex-end !important;
      display: flex;
      align-items: center;
      .menu-icon {
        display: block;
      }
      .navigation {
        position: fixed;
        top: 0;
        background-color: $white;
        width: 100%;
        height: 100%;
        padding-left: 0;
        transition: all ease-in-out 250ms;
        .close-menu {
          display: block;
        }
        .menu {
          margin-top: 50px;
          flex-direction: column;
          .menu-item {
            padding: 30px 0;
            &.more-item {
              .dropdown-menu-wrapper {
                top: 64px;
              }
            }
            &.email {
              margin: auto;
              .item {
                color: $primary-black;
              }
            }
          }
        }
      }
    }
  }
  
}

@media (max-width: 767px) {

  .header{
    .logo {
        width: 9rem;
        float: left;
        .width-100{
          height: auto;
        }
    }
    .container{
      padding-left: 15px;
      padding-right: 15px;
      display: block !important;
      .ml-auto.d-flex {
          float: right;
          margin: 2px 0px;
          .setting{
            display: none;
          }
      }
    }
  } 
  .header-top{
    .header-top-inner{
      .customer-login{
        margin-right: 20px;
        // border-top: 25px solid #2d2d2d;
        // width: 136px;
        // .customer-toggle{
        //   top: -25px;
        //   left: 0;
        //   width: 100%;
        //   .btn{
        //     font-size: 1.2rem;
        //   }
        // }
      }
    } 
  } 
  .auth-container{
    .auth-form-wrapper{      
      .primary{
        line-height: 2rem;
      }
      .form-field{
        margin-bottom: 10px;
        .input{
          padding: 10px 15px;
        }
        label{
          font-size: 15px;
        }
      }
    } 
  } 
  .logincls{
    .heading{
      font-size: 2rem;
      margin: 15px 0 0px;
    }
    .sub-heading{
      margin: 10px 0 10px;
      font-size: 1.5rem;
    }
  } 

  .header {   
    .ref {
      .navigation {
        .menu {
          .menu-item {
            padding: 0px 0;
          }
        }
      }
    }
    .navigation {
      .menu {
        .menu-item {
          .item {
            display: block;
          }
        }
      }
    }
  }
 
}
