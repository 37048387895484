@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.customer-review {
  float: left;
  width: 100%;
  margin-bottom: 52px;
  margin-top: 81px;
  background: url("./../../Assets/Images/fixedbg/cargo-fixed-design.png") center
  center no-repeat;
  background-size: cover;
  min-height: calc(100vh - 133px);
  background-attachment: fixed;
  .container-customer-review {
    max-width: 690px;
    background: #fff;
    margin: 0 auto;
    padding: 27px 50px;
    height: calc(100vh - 133px);    
    h1.car-name{
      color: #2d2d2d;
      font-family: Poppins;
      font-size: 32px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 19.95px;
      text-align: center;
      margin-bottom: 30px;
    }
    .heading {
      @extend %heading;
    }
    .text {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}


.customer-review-lists{
  ul.review-items {
      margin: 0px -10px;
      padding: 0;
      list-style: none;
      li{
        width: 50%;
        display: inline-block;
        vertical-align: top;
        h3{
          color: #128a38;
          font-family: Poppins;
          font-size: 1.5rem;
          font-weight: 600;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left;
        }
        p{
          color: #2d2d2d;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left;
        }
        .review-content {
            min-height: 105px;
        }
        .customer-name {
            margin: 20px 0px 10px;
            p{
              font-size: 1rem;
            }
        }
        .divider{
          width: 100%;
          img{
            width: 100%;
          }
        }
      }
  }
}


@media (max-width: 767px) {

  .customer-review{
    .container-customer-review{
      padding: 27px 15px;
      height: auto;
      overflow-y: auto;
    }
  } 

  .customer-review-lists{
    ul.review-items{
      margin: 0px 0px;
      li{
        width: 100%;
      }
    } 
  } 

}