@import "./../../Styles/theme";
@import "./../../Styles/placeholder";


.edit-profile{
    .heading{
        margin-bottom: 20px;
    }
    .fieldset{
        .checkbox-box{
            padding: 0px 15px;
        }
        h2{
            font-size: 2em;
            padding: 0 15px;
        }       
    }
    .edit-profilefield{
        .edit-profile-form{
            margin: 0px -15px;
            .submit-mass{
                padding: 0 15px;
                .btn{
                    margin: 15px 0px 0px;
                }
            }
            .form-field{
                width: 50%;
                display: inline-block;
                padding: 0 15px;
                margin-bottom: 15px;
                .pre-wrap-row{
                    margin: 0px -10px;
                    .pre-wrap-col{
                        width: 50%;
                        padding: 0px 10px;
                        float: left;
                    }
                }
                .text-uppercase {
                    text-transform: none;
                }
                label{
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .edit-profile{
        margin-bottom: 30px;
        .edit-profilefield{
            .edit-profile-form{
                .form-field{
                    width: 100%;
                }
            } 
        } 
    } 

}