@import "./../../../Styles/theme";
.common-banner {
  position: relative;
  .banner-inage {
    width: 100%;
  }
  .common-container {
    display: flex;
    align-items: center;
    position: relative;
    .common-container-inner {
      max-width: 1080px;
      width: 100%;
      margin: 0 auto;
      padding-top: 81px;
      .icon {
        width: 200px;
        img {
          width: 200px;
        }
      }
      .car-detail {
        width: calc(100% - 236px);
        padding-left: 60px;
        color: $primary-black;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
        .car-model {
          font-size: 20px;
          font-weight: $boldest;
        }
        .car-name {
          font-size: 2.5rem;
          font-weight: $boldest;
        }
        .detail {
          .info {
            font-size: 18px;
            font-weight: $tiny-bold;
            color: $primary-black;
            margin-right: 36px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              right: -20px;
              top: 50%;
              transform: translateY(-50%);
              background-color: $white;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .car-listing,.customer-review,.selling-process,.about-us,.contact-us,.wrapper{
    .common-banner{
      .common-container{
        .common-container-inner{
          .car-detail{
            .car-name{
              padding: 10px 0px;
            }
          } 
        } 
      } 
    } 
  }
  
  .common-banner {    
    padding: 80px 0 0px 0;
    background-size: cover;
    // display: flex;
    align-items: center;
    .banner-image {
      display: none;
    }
    .common-container {
      position: static;
      .common-container-inner {
        justify-content: center;
        margin-bottom: 0px;
        padding-top: 10px;

        .icon {
          width: 120px;
          img {
            width: 100%;
          }
        }
        .car-detail {
          padding-left: 30px;
          .car-model {
            font-size: 14px;
          }
          .car-name {
            font-size: 20px;
            margin-bottom: 4px;
          }
          .detail {
            .info {
              font-size: 12px;
              margin-right: 20px;
              &::after {
                width: 4px;
                height: 4px;
                right: -12px;
                top: 9px;
              }
            }
          }
        }
      }
    }
  }
}
