@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.wrapper{
  &.bg{
    float: left;
    width: 100%;
    margin-bottom: 52px;
    margin-top: 81px;
    background: url("./../../Assets/Images/fixedbg/cargo-fixed-design.png") center
    center no-repeat;
    background-size: cover;
    min-height: calc(100vh - 133px);
    background-attachment: fixed;
  }
} 

.dealer-signupc-main {
  max-width: 690px;
  background: #fff;
  margin: 0 auto;
  padding: 27px 0px;
  height: calc(100vh - 133px);
  
  .car-detail{
    .car-name{
      color: #2d2d2d;
      font-family: Poppins;
      font-size: 32px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 19.95px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

.auth-container {
  @extend %container1080;
  display: flex;
  align-items: center;
  justify-content: center;
  .auth-form-wrapper {
    max-width: 500px;
    width: 100%;
    margin-top: 81px;
    margin-bottom: 50px;
    .form-field {
      @extend %auth-field;
    }
    .btn {
      margin: 0;
    }
  }
}

.auth-container-two {
  @extend %container500;
  margin-top: -5px;
  .head {
    .heading {
      margin-bottom: 30px;
      @extend %heading;
    }
  }
  .auth-form-wrapper {
    margin-bottom: 50px;
    .form-field {
      @extend %auth-field;
      .cg-select {
        select {
          border: solid 1px #cfcfcf;
          outline: none;
          margin-top: 20px;
          option {
            padding: 5px 10px;
            margin: 5px 0px;
          }
        }
      }
      .cg-radio {
        input {
          display: inline-block;
          vertical-align: middle;
          margin: 0px 10px 0px 0px;
        }
        label {
          display: inline-block;
          vertical-align: middle;
          width: auto;
          margin: 0;
        }
      }
      .pre-wrap-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .pre-wrap-col {
          width: 50%;
          padding: 0 15px;
          .input {
            width: 100%;
          }
        }
      }
    }
  }
}

// color-radio-login
.color-radio-login{
  label{
    color: #2d2d2d !important;
  }
}

.forgot-password-inner {
  padding: 20px 0;
  text-align: center;
  .heading {
    margin-bottom: 20px;
  }
  .fieldset {
    .submit-mass {
      margin: 20px 0px;
    }
  }
}

// bg image
.login-bg {
  background: url("./../../Assets/Images/fixedbg/cargo-fixed-design.png") center
    center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

@media (max-width: 767px) {
  .auth-container-two {
    .auth-form-wrapper {
      margin-bottom: 30px;
    }
  }
}

.logincls {
  .heading {
    
    font-size: 32px;
    line-height: 1.5;
    margin: 20px 0;
    text-align: center;
    font-family: "Poppins";
  }
  .sub-heading {
    font-size: 32px;
    line-height: 1.5;
    margin: 10px 0;
    text-align: center;
    font-family: "Poppins";
    font-size: 22px;
  }
  .signUpCopy {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    transition: border 0.15s ease-in-out;
    margin: 0px 0 15px;
    color: #000;
    .cg-link {
      color: #000;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
  .forgot-pass {
    text-align: center;
    .forgot-link {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      transition: border 0.15s ease-in-out;
      display: inline-block;
      margin: 16px 0;
      color: #000;
      text-decoration: underline;
    }
  }
  .form-field {
    .logincheck {
      display: inline-block;
      vertical-align: middle;
    }
    .logincheck-label {
      display: inline-block !important;
      vertical-align: middle;
      width: auto !important;
      margin-bottom: 0 !important;
      margin-left: 5px;
    }
  }
}
