@import "./../../../Styles/theme";
@import "./../../../Styles/placeholder";

.customer-message {
  margin-bottom: 100px;
  .container-message {
     @extend %container1080;
   }
}

.customer-message-wrapper{
  width: 100%;
  float: left;
  .customer-message-wrapper-inner {
    width: 100%;
    float: left;
    padding: 0px;
    box-shadow: 0 1px 8px 0 #ccc;
}
  margin-bottom: 50px;
  .customer-name-lft{
    width: 33%;
    float: left;
    h3{
      padding: 16px;
      border-bottom: 2px solid #f0f0f0;
      box-shadow: 0 1px 2px 0 #ccc;
      background-color: #fff;
      font-weight: 500;
      font-size: 18px;
    }
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        display: block;
        margin-bottom: 0 !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        span{
          padding: 10px 20px;
          font-size: 18px;
          display: block;
          color: #000;
          cursor: pointer;
          width: 100%;
          &.active{
            background-color: #138b38;
            span{
              color: #fff;
              svg{
                fill: #fff;
              }
            }
          }
          span{
            padding: 0px 0px;
          }
        }
      }
    }
  }
  .customer-message-rgt{
    width: 67%;
    float: left;
    h3{
      padding: 16px;
      border-bottom: 2px solid #f0f0f0;
     box-shadow: 0 1px 2px 0 #ccc;
      background-color: #fff;
      font-weight: 500;
      font-size: 18px;
    }
    .mass-box{
      margin-bottom: 0px;
      width: calc(100% - 40px);
      display: inline-block;
      border-right: 1px solid #f1f1f1;
      border-left: 1px solid #f1f1f1;
    }
    .masscopy-add{
      a{
        width: 40px;
        height: 40px;        
        display: block;
        text-align: center;
        position: relative;
        svg{
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          margin: 0 auto;
        }              
      }
    }
    .submit-mass{
      .send{
        width: 40px;
        height: 40px;       
        display: block;
        text-align: center;
        position: relative;
        cursor: pointer;
        svg{
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          margin: 0 auto;
        }  
      }
    }
  }
}

.customer-message-inner {  
  width: 100%;
  margin: 0 auto;
  display: block;
  border: 1px solid #ccc;
  padding: 0px 0px 0px;
  position: relative;
  margin-top: 0px;
  .masscopy-add{
    display: inline-block;
    vertical-align: middle;
  }
  .mass-box{
    display: inline-block;
    vertical-align: middle;
  }
  .submit-mass{
    display: inline-block;
    vertical-align: middle;
  }
  .customermass-title {
    position: absolute;
    top: -16px;
    background: #fff;
    padding: 0px 10px;
    h2 {
      font-size: 20px;
    }
  }
  .select-ct-message {
    margin-bottom: 15px;
    .cg-select {
      border: 1px solid #61615f;
      border-radius: 4px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      margin: 0;
      outline: none;
      text-align: left;
      width: 100%;
      padding: 0 32px 0 16px;
      cursor: pointer;
    }
  }
  .mass-box {
    margin-bottom: 0px;
    textarea {
      width: 100%;
      border: none;
      border-radius: 4px;
      height: auto;
      margin-bottom: 0;
      outline: none;
      box-shadow: none;
      padding: 10px 10px;
      height: 40px;
    }
  }
  .submit-mass {
    .btn {
      width: 100%;
      margin: 0;
    }
  }
}

// chat

.customer-chat {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 330px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-image: url(../../../Assets/Icons/chatbgcg.svg);
  background-color: #f1f1f1;
  padding: 40px;
  margin-bottom: 0px;
  .rano {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .message-in {
    align-items: flex-start;
    .chatbox {
      position: relative;
      flex: none;
      color: var(--message-primary);
      font-size: 14.2px;
      line-height: 19px;
      max-width: 75%;
      width: 100%;
      margin-bottom: 15px;
      &:before{
        content: "";
        background-image: url(../../../Assets/Icons/chatlftbg.svg);
        position: absolute;
        top: 0px;
        z-index: 100;
        display: block;
        width: 8px;
        height: 13px;
        left: -8px;
      }
      &.ml-auto{        
        .mass-cont{
          background-color: #dcf8c6;
        }
        &:before{
          display: none;
        }
        &:after{
          content: "";
          background-image: url(../../../Assets/Icons/chatrgtbg.svg);
          position: absolute;
          top: 0px;
          z-index: 100;
          display: block;
          width: 8px;
          height: 13px;
          right: -8px;
        }
      }
      span.radius-iconlft {
        position: absolute;
        top: -2px;
        z-index: 100;
        display: block;
        width: 8px;
        height: 13px;
        left: -8px;
      }
      .date {
        font-size: 10px;
        margin-top: 10px;
        opacity: 0.3;
        font-style: italic;
      }
      .mass-cont {
        background-color: #fff;
        box-shadow: 0 1px 0.5px rgba(var(--shadow-rgb), 0.13);
        border-radius: 7.5px;
        padding: 6px 7px 8px 9px;
        border-top-left-radius: 0;
        .color-3 {
          color: #e542a3;
        }
        &.thirdperson {
          .color-3 {
            color: #35cd96;
          }
        }
      }
    }
  }
  .focusable-list-item {
    margin-bottom: 12px;
  }
  .message-out {
    align-items: flex-end;
    .chatbox {
      position: relative;
      flex: none;
      color: var(--message-primary);
      font-size: 14.2px;
      line-height: 19px;
      max-width: 75%;
      width: 100%;
    }
    .radius-iconrgt {
      position: absolute;
      top: -2px;
      z-index: 100;
      display: block;
      width: 8px;
      height: 13px;
      right: -8px;
    }
    .mass-cont {
      background-color: #dcf8c6;
      box-shadow: 0 1px 0.5px rgba(var(--shadow-rgb), 0.13);
      border-radius: 7.5px;
      padding: 6px 7px 8px 9px;
      border-top-left-radius: 0;
    } 
  }
}
