@import "./../../Styles/theme";

.footer {
  background-color: $z-black;
  padding: 20px 0 20px 0;
  color: $white;
  width: 100%;
  float: left;
  .col-logo {
    width: 31%;
  }
  .col-footer {
    width: 23%;
    // .logo {
    //   width: 238px;
    // }
  }
  .footer-card {
    .heading {
      font-size: 1.375rem;
      font-weight: 700;
      margin-bottom: 2.5rem;
    }
    ul {
      li {
        a {
          font-size: 1rem;
          font-weight: $tiny-bold;
          margin-bottom: 2rem;
          color: $white;
          display: block;
          &:hover{
            color: $orange;
          }
        }
      }
      &.contact-footer {
        li {
          margin-bottom: 10px;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
  
  
}

.cargo-socal-media {
  width: 100%;
  float: left;
  text-align: center;
  .socal-media{
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        display: inline-block;
        padding: 0px 10px;
        a{
          display: block;
          &:hover{
            svg{
              fill: $orange;
            }
          }
        }
      }
    }
  }
}

.cargopolicy{
  margin-top: 15px;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      display: inline-block;
      padding: 0px 10px;
      a{
        font-size: 1rem;
        font-weight: $tiny-bold;
        margin-bottom: 2rem;
        color: $white;
        display: block;
        &:hover{
          color: $orange;
        }
      }
    }
  }
}

// @media
@media (max-width: 767px) {
  .footer {
    .copyright{
      margin-top: 0;
      width: 100%;
      float: left;
    }
    .container {
      flex-wrap: wrap;
      .footer-car {
        display: none;
      }
      .col-logo {
        width: 100%;
        margin-bottom: 25px;
        .logo {
          width: 150px;
        }
      }
      .col-footer {
        width: 50%;
        margin-left: 0 !important;
        .heading {
          margin-bottom: 20px;
        }
        ul {
          margin-bottom: 20px;
          li a {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}


// New

.footer{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 0 5px 0;
  z-index: 10;
}

.footer-md{
  .cg-sm-2{
    width: 15%;
    padding: 0px 15px;
    a{
      color: #999;
      font-size: 23px;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
  .cg-sm-8{
    width: 70%;
    padding: 0px 15px;
    text-align: center;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        display: inline-block;
        vertical-align: middle;
        font-size: 1.125rem;
        color: #999999;
        padding: 0px 15px;
        letter-spacing: normal;
        font-weight: normal;
        .fticon{
          display: inline-block;
          vertical-align: middle;
          margin-right: 3px;
          width: 15px;
        }
        span{
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  
}

.footer-bt{
  margin-top: -10px;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li{
      display: inline-block;
      font-size: 1rem; 
      color: #999;
      font-weight: 400;
      padding: 0px 10px;
      position: relative;
      &::before{
        content: ".";
        font-size: 26px;
        position: absolute;
        top: -17px;
        display: inline-block;
        left: 0;
      }
      &:nth-child(1),&:nth-child(2){
        &:before{
          display: none;
        }
      }
      a{
        color: #999;
      }
    }
  }
}

.dealerlogin{
  .dealerlogin-inner{
    position: relative;
    .menu-item{
      position: absolute;
      border-bottom: 30px solid #2d2d2d;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      height: auto;
      width: 187px;
      text-align: center;
      display: block;
      float: right;
      right: 0px;
      bottom: 5px;
      .item{
        color: #fff;
        font-size: 14px;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -28px;
      }
    }
  }
}

.footer-bt-copyright-mobile{
  display: none;
}

@media (max-width: 1100px) {

  .footer-md{
    .cg-sm-8{
      width: 65%;
      ul{
        li{
          font-size: 0.925rem;
          padding: 0px 5px;
        }
      } 
    }
    .cg-sm-2{
      width: 20%;
      &:first-child{
        width: 15%;
      }
    }
    .cargo-socal-media{
      .socal-media{
        ul{
          li{
            padding: 0px 5px;
          }
        } 
      } 
    } 
  } 

}

@media (max-width: 767px) {

  .footer{
    position: relative;
    padding: 15px 0 15px 0;
    .footer-bt-copyright{
      display: block;
    }
    .dealerlogin{
      .dealerlogin-inner{
        .menu-item{
          bottom: 9px;
          left: 0;
          margin: 0 auto;
        }
      } 
    } 
    .footer-md{
      text-align: center;
      .cargo-socal-media{
        margin: 15px 0px 5px;
      }
      .cg-sm-2{
        width: 100%;
        a.footerlogo{
          margin: 10px 0;
          display: block;
        }
      }
      .cg-sm-8{
        width: 100%;
        ul{
          li{           
            padding: 0px 0px;
            width: 100%;
          }
        } 
      }
    }
    .footer-bt{
      ul{
        li{          
          &:nth-child(1){
            display: none;
          }
        }
      } 
    } 
    .footer-bt-copyright-mobile {
        text-align: center;
        color: #999;
        font-weight: 300;
        padding: 0px 10px;        
        .copyright{
          margin-top: 5px;
          margin-bottom: 15px;
        }
    } 
  }
  

}