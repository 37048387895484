@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.customer-profile {
  @extend %common-gradient;
  margin-bottom: 0px;
  min-height: 100vh;
  padding-bottom: 50px;
  .container-customer {
    @extend %container1080;
  }
}

.customer-profilemain {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
}

.customer-profile {
  .filterSection {
    text-align: right;
    margin-bottom: 32px;
    .cg-select {
      border: 2px solid #61615f;
      border-radius: 4px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      margin: 0;
      outline: none;
      text-align: left;
      width: 250px;
      padding: 0 32px 0 16px;
      cursor: pointer;
    }
  }
  .sideBarSection {
    width: 28%;
    padding-right: 20px;
    .sideBarSection-inner {
      padding: 0px;
      border-radius: 10px;
      overflow: hidden;
      .sideBarSection-cg {
        .navbar {
          .navbar__link {
            background-color: $primary-black;
            display: block;
            color: $white !important;
            padding: 12px 15px;
            cursor: pointer;
            color: inherit;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            border-bottom: 2px solid $green;
            &.active {
              background-color: $green;
            }
            &:last-child {
              border: 0;
            }
          }
        }
      }

      .sideBarSection-db {
        display: none;
        padding: 20px 0px;
        width: 100%;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            width: 100%;
            padding: 10px 0;
            a {
              font-size: 16px;
              color: #000;
            }
          }
        }
      }
    }
  }
  .filterSection {
    width: 60%;
  }
  .vehiclesSection {
    width: 72%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li.list {
        background: #f1f1f1;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(29, 29, 27, 0.5);
        contain: content;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        margin-bottom: 20px;
        .item {
          width: 100%;
          display: block;
          vertical-align: middle;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px transparent;
          position: relative;
          transition-property: color;
          transition-duration: 0.3s;
          &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #cecece;
            transform: scaleY(0);
            transform-origin: 50% 0;
            transition-property: transform;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
          }
          &:hover:before {
            transform: scaleY(1);
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .customer-profile {
    .vehiclesSection {
      width: 100%;      
    }
    .sideBarSection {
      display: none;
    }
  }
}

.accountVehicleCardView {
  display: flex;
  height: 253px;
  .vehiclesSection-lft {
    width: calc(50% - 20px);
    .photoArea {
      width: 100%;
      max-height: 253px;
      overflow: hidden;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
  .vehiclesSection-rgt {
    width: calc(50% - 0px);
    padding: 14px 0px 0px 10px;
    box-sizing: border-box;
    position: relative;
    .bid-ammount-date{
      font-size: 16px;
      .bidsamount{
        .mw-title-title6{
          font-weight: 700;        
        }
      }   
    }
    .bidArea{
      font-size: 16px;   
      .mw-title-title6{
        font-weight: 700;        
      }
      .text-green{
        font-weight: 600;
      }
    }
    .vrmArea{
      .vrmBadge-styles_badge {
        font-size: 18px;
        text-transform: uppercase;
        color: #2d2d2d;
        border: 4px solid #000000;
        background-color: #edb92d;
        border-radius: 6px;
        line-height: 100%;
        letter-spacing: 1.42px;
        margin-right: 5px;
        display: inline-block;
        clear: both;
        padding: 5px 6px;
        font-weight: 700;
    }
    
    }
    ul.tab {
      margin-bottom: 1rem;
      justify-content: space-between;
      li {
        padding: 3px 6px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        font-weight: 700;
        &.title {
          font-size: 18px;
          text-transform: uppercase;
          color: $primary-black;
          border: 4px solid #000000;
          background-color: #edb92d;
          border-radius: 6px;
          line-height: 100%;
          letter-spacing: 1.42px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
        }
        &.location {
          border: 4px solid $primary-black;
          background-color: $primary-black;
          font-size: 15px;
          color: $white;
          min-width: 130px;
          margin-left: 5px;
          .icon {
            width: 10px;
            margin-right: 5px;
          }
        }
      }
    }
    .card-heading {
      font-size: 1.325rem;
      margin-bottom: 10px;
      color: $primary-black;
      line-height: 12px;
      text-align: center;
    }
    ul.feature {
      display: flex;
      margin-bottom: 10px;
      justify-content: center;
      li {
        width: auto;
        font-size: 16px;
        color: #797979;
        font-weight: $tiny-bold;
        padding-right: 20px;
        position: relative;
        box-shadow: none;
        &::after {
          position: absolute;
          right: 7px;
          top: 7px;
          content: "";
          height: 4px;
          width: 4px;
          background-color: #a1a1a1;
          border-radius: 50%;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }

    .nameArea {
      width: 100%;
      span {
        font-size: 16px;
        color: #000;
        margin: 10px 0px;
        display: block;
      }
    }
    .bidArea,
    .bidsamount,
    .bidsdate {
      width: 100%;
      a.CommentIcon-cls {
        float: right;
      }
    }
    .bid-status {
      font-weight: 700;
      font-size: 16px;
      .text-green,.text-danger{
        font-weight: 600;
      }
      .createdAt{
        font-weight: 500;
      }
    }
    .aution-date {
      position: absolute;
      bottom: 0;
      width: calc(100% + 20px);
      left: 0;
      background: #1b893d;
      color: #ffffff;
      font-size: 21px;
      text-align: center;
      padding: 3px;
    }
    .viewdetail {
      display: inline-block;
      vertical-align: top;
      position: absolute;
      right: 0px;
      bottom: 46px;
      text-align: center;
      .mark-completed{
        .btn.primary{
          height: auto;
        }
      }
      .btn {
        width: 100%;
        display: block;
        text-align: center;
        margin: 6px auto;
        padding: 2px 10px;
        line-height: 3rem;
        &.chatbtn {
          position: relative;
          height: 28px;
          background-color: $orange;
          svg {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .bid-ammount-date {
      width: 100%;
      .bidsamount,
      .bidsdate {
        width: auto;
        float: none;
      }
    }
  }
}

.pull-right {
  &.pagination {
    text-align: center;
    margin: 0 auto;
    display: block;
    li {
      display: inline-block !important;
      width: 40px !important;
      height: 40px !important;
      text-align: center;
      line-height: 40px;
      border-radius: 50% !important;
      margin: 0px 5px;
      float: none !important;
      transition-property: color;
      transition-duration: 0.3s;
      &:hover {
        background-color: $orange;
        a {
          color: #fff;
        }
      }
      a {
        cursor: pointer;
        display: block;
        outline: none;
      }
      &.active {
        background-color: $green;
        a {
          color: #fff;
        }
      }
      &.previous {
        a {
          font-size: 0;
          &:before {
            content: "<<";
            font-size: 16px;
          }
        }
      }
      &.next {
        a {
          font-size: 0;
          &:before {
            content: ">>";
            font-size: 16px;
          }
        }
      }
    }
  }
}
.mark-completed {
  .btn.primary {
    position: relative;
    height: 28px;
    background-color: #f39000;
    padding: 5px 10px;
  }
}

@media (max-width: 767px) {
  .customer-profile .container-customer {
    padding-bottom: 30px;
  }
  .customer-profilemain {
    display: block;
  }
  .accountVehicleCardView {
    display: block;
    height: auto;
  }
  .accountVehicleCardView .vehiclesSection-lft {
    width: 100%;
  }
  .accountVehicleCardView .vehiclesSection-rgt .bid-ammount-date {
    margin-bottom: 35px;
  }
  .customer-profile {
    .vehiclesSection {
      width: 100%;
      .accountVehicleCardView {
        .vehiclesSection-lft {
          float: none;
          margin: 0 auto;
        }
        .vehiclesSection-rgt {
          width: 100%;
          padding: 14px 10px 14px 10px;
          .viewdetail {
            right: 10px;
            transform: none;
          }
        }
      }
    }
    .sideBarSection {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
      .sideBarSection-inner {
        .sideBarSection-cg {
          .navbar {
            .navbar__link {
              padding: 10px 20px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  
  .customer-profile{
    .vehiclesSection{
      .accountVehicleCardView{
        .vehiclesSection-rgt{
          .viewdetail{
            position: relative;
            right: inherit;
            bottom: inherit;
            width: 100%;
          }
        } 
      } 
    } 
  } 
   
  .accountVehicleCardView{
    .vehiclesSection-rgt{
      .bid-ammount-date{
        margin-bottom: 0;
      }
    } 
  } 

  .accountVehicleCardView{
    .vehiclesSection-rgt{
      .aution-date{
        position: relative;
        width: 100%;
        left: initial;
      }
    } 
  } 

}
