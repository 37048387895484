@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.dealerprocess-conditions {
  margin-bottom: 52px;
  margin-top: 81px;
  background: url("./../../Assets/Images/fixedbg/cargo-fixed-design.png") center
    center no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 153px); 
  background-attachment: fixed;
    .container-dealerprocess {
        width: 100%;
        padding: 27px 30px;
        text-align: center;
        max-width: 690px;
        margin: 0 auto;
        margin-bottom: 0px;
        padding-bottom: 30px;
        height: calc(100vh - 133px);
        background-color: white;
        
        .headtitle{
            h1{
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 32px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 19.95px;
            text-align: center;
            margin-bottom: 30px;
            }
        }
        .dealerprocess-inner{
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: left;
                width: 50%;
                padding: 0px 10px;
                display: inline-block;
                vertical-align: top;
                li{
                    display: inline-block;
                    width: 100%;                    
                    padding: 0px 0px;
                    margin-bottom: 10px;
                    vertical-align: top;
                    &.pro-mobile{
                        display: none;
                    }
                    &.pro-desktop{
                        margin-top: 100px;
                    }
                    &:nth-child(4){
                        clear: left;
                    }
                    .process{
                        background-color: #fff;
                        border-radius: 10px;
                        padding: 12px 17px;
                        position: relative;
                        .pro-number{
                            position: absolute;
                            left: 17px;
                            top: 12px;
                            color: #128a38;
                            font-family: Poppins;
                            font-size: 46px;
                            font-weight: 700;
                            font-style: normal;
                            letter-spacing: normal;
                            line-height: 40px;
                        }
                        .process-img{
                            text-align: center;
                            img{
                                max-width: 200px;
                                margin: 0 auto;
                            }
                        }
                        .process-title{
                            h3{
                                color: #128a38;
                                font-family: Poppins;
                                font-size: 18px;
                                font-weight: 500;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: normal;
                                text-align: left;
                            }
                        }
                        .process-dis{
                            color: #2d2d2d;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 500;
                            font-style: normal;
                            letter-spacing: normal;
                            line-height: normal;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }    
  }

 

  @media (max-width: 767px) {

    .dealerprocess-conditions{
        .container-dealerprocess{
            .dealerprocess-inner{
                ul{
                    width: 100%;
                    li{
                        &.pro-desktop{
                            display: none;
                        }
                        &.pro-mobile{
                            display: block;
                        }
                        .process{
                            .process-img{
                                img{
                                    max-width: 160px;
                                }
                            } 
                        } 
                    } 
                }
            } 
        } 
    } 
    
  }