@import "./../../../Styles/theme";

textarea {
  box-shadow: -6px 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid $border-color;
  padding: 20px;
  margin-bottom: 20px;
  &.input-error {
    border: 1px solid $danger !important;
  }
}

.textarea-wrapper {
  margin-bottom: 20px;
  width: 100%;
  .text-area {
    width: 100%;
    min-height: 135px;
  }
}
