@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.filter-main {
  position: relative;
  .filterpopup {
      position: absolute;
      top: 42px;
      left: 0;
      right: 0;
      .custom-popup{
        position: relative;
        .popup{
          .header-popup{
            padding: 0;
            border-bottom: 3px solid #00933a;
            .times{
              cursor: pointer;
            }
          }
        } 
      }
  }
}

.filterpopup{
  .custom-popup.show{
    background-color: transparent;
  }
  .custom-popup .popup .filter-field .input-range + span {
      margin-top: 15px;
  }
  span.input-range__label-container {
      display: none !important;
  }
  .popup{
    width: 100%;
    .filterpopup-inner{

      .filter-control{
        width:50%;
        padding:5px 20px;
      }
      .primary{
        line-height: 32px;
      }
      .col-sm-6{
        width: 33.33%;
        padding-left: 15px;
        padding-right: 15px;
      }
      .col-sm-12{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      }
      .filter-field{    
        label.filterlabel.fuel {
            padding-left: 15px;
        }    
        ul{
          margin: 0;
          padding: 0;
          list-style: none;
          text-align: left;
          li{
            display: inline-block;
            vertical-align: middle;
            margin: 0px 15px;
            span{
              display: inline-block;
              vertical-align: middle;
            }
            input{
              display: inline-block;
              vertical-align: middle;
              width: auto;
              margin: 0px 5px 0px 0px;
            }
          }
        }
      }
    }
  }
}

.car-listing {
  @extend %common-gradient;
  // @extend %common-gradient-image;

  .listing-head {
    @extend %container1080;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    background-color: #2d2d2d;
    padding: 8px 13px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    color: $white;
    height:50px;

    .label-button{
      display: flex;
      position: relative;
      top:-25px;

      .spacer{
        flex-grow:1;
      }
    }
    .btn {
      width: 200px;
      margin: 0;
    }
    .filter-caption {
      font-size: 1.5rem;
      font-weight: 700;
      cursor: pointer;
    }
    .heading {
      font-size: 1.42rem;
      font-weight: 300;
      margin-left: 15px;
      margin-right: 15px;
      text-align: center;
    }
    .icon-close {
      &.fliter-hide {
        transform: rotate(45deg);
      }
      &.filter-open {
        transform: rotate(0deg);
      }
    }
    .input {
      width: 200px;
    }
  }
  .listing-wrapper {
    @extend %container1080;
    padding-bottom: 72px;
    .card-outer-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -25px;
      .card-wrapper {
        width: calc(100% / 3);
        padding-left: 10px;
        padding-right: 10px;
       .list-card{
         @extend %list-card;
       }
      }
    }
  }
  .filterpopup{
    @extend %container1080;
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
  }

  .btn-small{
    display: inline-block;
    padding:5px 15px;
    width: auto !important;
  }
}

.filterpopup-inner{
  .input-range__track{
    height: 1rem;
   
  }
  .input-range__track--active{
    background: #00933a;
  }
  .input-range__slider{
    background: #00933a;
    border: 1px solid #00933a;
    height: 1.7rem;
    width: 1.7rem;
    transform: translateY(-50%);
  }
  .input-range__label--min, .input-range__label--max{
    display: none;
  }
  .input-range__label--value{
    display: none !important;
  }
}
.filterpopup .custom-popup .popup .filter-field span.valueslide {
  margin-top: 20px;
}


@media (max-width: 1000px){
  .car-listing {
    .listing-wrapper {
      .card-outer-wrapper {
        margin: 0;
        .card-wrapper {
          width: 50%;          
        }
      }
    }
  }
}
// @media
@media (max-width: 767px) {
  .car-listing {
    .listing-wrapper{
      .card-outer-wrapper{
        .card-wrapper{
          .list-card{
            .card-body{
              padding: 15px 10px 15px 10px;
              ul.tab{
                li{
                  margin: 0 5px;
                }
              } 
            }
          } 
        } 
      } 
    } 
    .common-banner{
      padding: 47px 0 0px 0;
      .common-container{
        .common-container-inner{
          .car-detail {
            padding-bottom: 0;
            padding-top: 0;
          }
        } 
      } 
    }
    .listing-head, .filterpopup {     
      width: calc(100% - 30px);
    }
    .listing-wrapper {
      .card-outer-wrapper {
        margin: 0;
        .card-wrapper {
          width: 100%;
          padding: 0;
        }
      }
    }    
  }  
}

@media (max-width: 600px) {
  .car-listing {
    .listing-head {
      height: 80px;
      .label-button{
        top: -65px;
      }
      .heading {
        padding: 40px 15px 0;
      }
    }
  }
  .filterpopup {
    .popup{
      .filterpopup-inner{
        .filter-control{
          width: 100%;
        }
      }
    }
  }
}
.map_CarGrid{
    width:100% !important;
}