@import "./placeholder";

:root {
  font-size: 12px;
}

*,
:after,
:before {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}

body,
ul,
h1,
h2,
h3,
p {
  padding: 0;
  margin: 0;
}
a,
a:hover {
  text-decoration: none;
}

.text-green {
  color: $green !important;
}

.text-danger {
  color: $danger !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.container {
  max-width: 1235px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

// list style
.list-style-none {
  list-style: none !important;
}

// @margins
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 1rem !important;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.ml-2 {
  margin-left: 1rem !important;
}
.ml-3 {
  margin-left: 1.5rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.m-0 {
  margin: 0;
}

// padding
.pl-0 {
  padding-left: 0 !important;
}

// width height
.h-100 {
  height: 100% !important;
}
.width-100 {
  width: 100% !important;
}
.width-50 {
  width: 50%;
}

// cursor pointer
.cursor-pointer{
  cursor: pointer;
}

// @flex
.d-flex {
  display: flex !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-around !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}

// @display
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none;
}

// text alignment
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left !important;
}

// positions
.position-relative {
  position: relative;
}

// columns
.col-50 {
  width: 50%;
}

// radio button
.radio-button {
  position: relative;
  height: 60px;
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  label {
    background-color: $border-color;
    position: absolute;
    width: calc(100% - 4px);
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0b0b0b;
    font-size: 19px;
    font-weight: 400;
    cursor: pointer;
    &:checked {
    }
  }
  [type="radio"]:checked + label {
    font-weight: 700;
  }
}

.radio-button-wrapper {
  margin-bottom: 46px;
}
.react-datepicker-wrapper {
  width: 100%;
}

// date picker
.SingleDatePicker {
  width: 100%;
  .SingleDatePickerInput {
    width: 100%;
    // padding: 12px 15px;
    border-radius: 4px;
    border: solid 1px #cfcfcf;
    background-color: #ffffff;
    &:focus {
      border: 1px solid $green;
    }
  }
}
.DateInput_input {
  padding: 9px 11px 9px;
}
.DateInput_input__focused {
  border: 0;
  // padding: 9px 11px 9px;
}

// toast
.Toastify__toast--error {
  bottom: 130px;
}

// typography
.text-primary {
  color: $green;
}
.text-white {
  color: $white;
}

.custom-scrollbar{
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #111;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #6d6d6d;  
  }
}

.ma-20px {
  margin:20px;
}