body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Pages Animation Starts */
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  display: none !important;
}

/* Pages Animation Ends */
