@import "./../../Styles/theme";
@import "./../../Styles/placeholder";

.cargo-home {
  overflow: hidden;
  .banner-fold {
    height: 60vh;
    position: relative;
    padding-top: 8.3125rem;
    background: url("./../../Assets/Images/banner-illustration.png") no-repeat
      top right #f1f1f1;
    background-size: contain;
    color: $primary-black;
    .banner-content-wrapper {
      margin-top: 0px;
      display: block;
      margin: 0 auto;
      max-width: 63.5rem;
      width: 100%;
      .banner-heading {
        font-size: 3.125rem;
        font-weight: $boldest;
        line-height: 4.0625rem;
        margin-bottom: 1rem;
        letter-spacing: 0.103125rem;
      }
      .tagline {
        font-size: 1.5rem;
        font-weight: $tiny-bold;
        margin-bottom: 1rem;
      }
      .banner-form-group {
        margin-bottom: 1.5rem;
        .input {
          min-width: 25.125rem;
          height: 44px;
          text-transform: uppercase;
          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            text-transform: capitalize;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            text-transform: capitalize;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            text-transform: capitalize;
          }
          &:-moz-placeholder { /* Firefox 18- */
            text-transform: capitalize;
          }
        }
      }
      .banner-rating {
        margin-bottom: 6rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .reviews {
          font-size: 1.125rem;
          font-weight: $tiny-bold;
          margin-left: 15px;
          margin-top: 13px;
        }
        .icon-star {
          margin-right: 1rem;
          display: block;
        }
        .icon-stars {
          margin-top: 13px;
        }
      }
    }
  }

  // campare companies and car
  .compare-compnies {
    padding-top: 2.5rem;
    max-width: 77.5rem;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    .head {
      border-bottom: 2px solid $border-color;
      .heading {
        text-align: center;
        font-size: 1.5rem;
        font-weight: $tiny-bold;
        margin-bottom: 1.375rem;
        padding-bottom: 1.375rem;
        span {
          font-weight: $boldest;
        }
      }
      .company-logos {
        padding: 0 37px;
        display: flex;
        flex-wrap: nowrap;
        .company-logo {
          padding: 10px;
          width: calc(100% / 6);
        }
      }
    }
    .company-features {
        margin: 0 -2.6875rem;
        padding-top: 3.5rem;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 3.5rem;
      .feature {
        width: 25%;
        padding: 0 2.6875rem;
        text-align: center;
        &.caricon{
          svg.icon{
            background: #00933a;
            padding: 18px;
          }
        }
        .icon {
          width: 6.25rem;
          height: 6.25rem;
          margin-bottom: 3rem;
          border-radius: 8px;
          font-size: 40px;
          margin: 0px 10px;
          line-height: 100px;
          display: inline-block;
          -webkit-transition: all 0.6s ease;
          -moz-transition: all 0.6s ease;
          -ms-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          transition: all 0.6s ease;
          &:hover {
            border-radius: 50%;
            background: #b71c1c;
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        .heading {
          font-size: 18px;
          font-weight: $tiny-bold;
          letter-spacing: -0.040625rem;
          margin-bottom: 2rem;
        }
        .text-description {
          font-size: 16px;
          line-height: 1.8125rem;
          font-weight: $tiny-bold;
          letter-spacing: -0.025rem;
          color: $secondary-black;
        }
      }
    }
  }
  // why sell
  .why-sell {
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.11);
    background-color: #f7f7f7;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .featured-image {
      width: 50%;
      margin-bottom: 50px;
      padding-right: 50px;
      .featured-icon {
        width: 100%;
      }
    }
    .content {
      width: 50%;
      .heading {
        @extend %heading;
      }
      .featured-point {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 50%;
          padding: 0 3.75rem;
          margin-bottom: 3.5rem;
          .point {
            // padding-left: calc(1.75rem + 30px);
            position: relative;
            &::before {
              top: 5px;
              left: -50px;
              position: absolute;
              content: "";
              background: url("./../../Assets/Icons/arrow-right.png");
              width: 24px;
              height: 24px;
              background-size: contain;
            }
            .point-heading {
              font-size: 1.5rem;
              font-weight: $tiny-bold;
              letter-spacing: -0.040625rem;
              margin-bottom: 1.125rem;
              color: $primary-black;
            }
            .point-text {
              font-size: 1rem;
              font-weight: $tiny-bold;
              line-height: 1.8125rem;
              letter-spacing: -0.025rem;
              color: $secondary-black;
            }
          }
        }
      }
    }
  }

  // sell any car
  .sell-any-car {
    background-color: $primary-red;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .heading {
      font-size: 2.875rem;
      color: $white;
      margin-bottom: 3.5rem;
      font-weight: $boldest;
    }
    .car {
      margin: 0 -10px;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin: 0 10px;
        width: calc(16.6666% - 20px);
        background-color: $white;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // sell you car
  .sell-your-car {
    margin: 0 -2rem;
    padding: 2.5rem 0;
    .inner-container {
      max-width: 1080px;
      width: 100%;
      padding: 0 16px;
      margin: 0 auto;
      display: flex;
      .content {
        width: 50%;
        padding: 0 2rem;
        .text-description {
          @extend %heading;
        }
        .text {
          font-size: 1rem;
          font-weight: $tiny-bold;
          line-height: 1.865rem;
          margin-bottom: 1.5rem;
          color: $secondary-black;
        }
        .btn {
          margin-top: 1rem;
          margin-left: 0;
        }
      }
      .featured-image {
        padding: 0 2rem;
        width: 50%;
      }
    }
  }

  // Join Thousands of Happy Customers
  .customer-review {
    padding: 2.5rem 0;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.11);
    background-color: #f7f7f7;
    .head {
      margin-bottom: 5rem;
      .heading {
        @extend %heading;
        text-align: center;
      }
    }
    .customers {
      display: flex;
      margin: 0 -22px;
      flex-wrap: wrap;
      .customer-card {
        margin: 0 1.375rem;
        width: calc(33.3333% - 44px);
        background-color: $white;
        padding: 3.25rem 3.125rem 5rem 3.125rem;
        border-radius: 6px;
        border: 2px solid #ebeaea;
        .profile {
          width: 6.25rem;
          height: 6.25rem;
          border-radius: 50%;
          margin-bottom: 2.5rem;
          img {
            width: 100%;
          }
        }
        .review-start {
          margin-bottom: 1.865rem;
        }
        .title {
          font-size: 1.5rem;
          font-weight: $tiny-bold;
          color: $primary-black;
          margin-bottom: 1.125rem;
        }
        .text {
          font-size: 1rem;
          font-weight: $tiny-bold;
          line-height: 1.8125rem;
          color: $secondary-black;
          margin-bottom: 1.5rem;
        }
        .name {
          font-size: 1.125rem;
          font-weight: $boldest;
          color: $primary-black;
          margin-bottom: 10px;
        }
        .car-sold {
          font-size: 1.125rem;
          font-weight: $tiny-bold;
          color: #848484;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .cargo-home {
    .sell-any-car {
      padding-top: 1.625rem;
      padding-bottom: 2.625rem;
    }
    .sell-your-car {
      margin: 0 0rem;
      padding: 2.75rem 0;
    }
    .customer-review {
      padding: 20px 0;
      margin-bottom: 0;
      .head {
        margin-bottom: 30px;
        .heading{
          margin-bottom: 0;
        }
      }
    }
    .banner-fold {
      background: none;
      background-color: #f1f1f1;
      height: auto;
      .banner-content-wrapper {
        text-align: center;
        margin: 0 auto;
        .banner-heading {
          font-size: 32px;
          margin-bottom: 20px;
        }
        .tagline {
          font-size: 16px;
          margin-bottom: 15px;
        }
        .banner-form-group {
          text-align: center;
          margin-bottom: 0;
          .input {
            margin-bottom: 20px;
          }
        }
        .banner-rating {
          justify-content: center;
          margin-bottom: 30px;
          .icon-star {
            width: 150px;
          }
          .icon-stars {
            width: 105px;
          }
          .reviews {
            display: block;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    .compare-compnies {
      padding-top: 30px;
      .head {
        .heading {
          margin-bottom: 30px;
          padding-bottom: 0;
        }
        .company-logos {
          padding: 0px;
          margin: 0 -15px;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          overflow: auto;
          .company-logo {
            margin: 0 5px;
            width: 101px;
          }
        }
      }
      .company-features {
        padding-top: 30px;
        padding-bottom: 30px;
        .feature {
          width: 100%;
          margin-bottom: 30px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -15px;
            height: 2px;
            width: 50%;
            left: 50%;
            transform: translateX(-50%);
            background: #f1f1f1;
          }
          .icon {
            margin-bottom: 20px;
          }
        }
      }
    }
    .why-sell {
      padding-bottom: 20px;
      padding-top: 30px;
      .container {
        flex-wrap: wrap;
      }
      .featured-image,
      .content {
        width: 100%;
      }
      .featured-image {
        margin-bottom: 30px;
      }
      .content {
        .heading {
          margin-bottom: 30px;
        }
        .featured-point {
          .item {
            width: 100%;
            padding-right: 0;
            padding-left: 50px;
            margin-bottom: 15px;
            .point {
              position: relative;
              &::after {
                position: absolute;
                content: "";
                width: 50px;
                height: 2px;
                left: 0px;
                bottom: -10px;
                background-color: #f1f1f1;
              }
              .point-heading {
                font-size: 20px;
              }
              .point-text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .sell-any-car {
      .car {
        flex-wrap: nowrap;
        overflow-y: auto;
        .item {
          width: 150px;
          height: auto;
          padding: 10px;
          img {
            max-width: 80px;
          }
        }
      }
    }
    .sell-your-car {
      .inner-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        .content {
          width: 100%;
          text-align: center;
          .text-description {
            text-align: center;
            margin-bottom: 20px;
          }
          .text {
            font-size: 14px;
          }
        }
        .featured-image {
          width: 80%;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
    .customer-review {
      .customers {
        .customer-card {
          width: 100%;
          margin-bottom: 15px;
          padding: 2.25rem 3.125rem 2.25rem 3.125rem;
          .title {
            font-size: 20px;
          }
          .text,
          .name,
          .car-sold {
            font-size: 14px;
          }
        }
      }
    }
  }
}
