.custom-popup {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  &.show {
    display: flex;
  }
  .popup {
    min-width: 300px;
    height: auto;
    background-color: #ffffff;
    border-radius: 4px;
    &.bynow-popup {
      max-width: 500px;
      width: 100%;
    }
    .header-popup {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid #dedede;
      .heading {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }
      .times {
      }
    }
    .popup-body {
      margin: 15px 15px;
      text-align: center;
      .text-buynow {
        font-size: 18px;
        font-weight: 400;
        span {
          font-weight: bold;
        }
      }
    }
    .footer-popup {
      border-top: 1px solid #dedede;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.filterpopup {
  .custom-popup {
    .popup {
      background-color: rgba(0, 0, 0, 0.8);
      .header-popup {
        position: relative;
        .heading {
          color: #fff;
          text-align: center;
          width: 100%;
          font-size: 20px;
          font-weight: 700;
        }
        .times {
          position: absolute;
          right: 10px;
          top: 10px;
          color: #fff;
        }
      }
      .filter-field {
        padding-bottom: 10px;
        border-bottom: 1px solid #393535;
        margin-bottom: 10px;
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        span {
          display: block;
          text-align: left;
          color: #fff;
        }
        .filterlabel {
          color: #fff;
          width: 100%;
          display: block;
          text-align: left;
          font-size: 14px;
          margin-bottom: 8px;
        }
        select,
        input {
          width: 100%;
        }
        button {
          border: 1px solid #fff;
          color: #fff;
          background-color: transparent;
          margin: 0px 5px;
          padding: 5px 25px;
          border-radius: 3px;
        }
      }
    }
  }
}

.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

.range::-webkit-slider-runnable-track {
  background-color: #00933a;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #00933a;
  background-color: transparent;
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #00933a;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower {
  background-color: #00933a;
  border-radius: 3px;
}

.range::-ms-fill-upper {
  background-color: #00933a;
  border-radius: 3px;
}

.range::-ms-tooltip {
  display: none; /* display and visibility only */
}

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: 1px solid #fff;
  background: none;
  background-color: transparent;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

.range[disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #00933a;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range::-ms-thumb {
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  border: none;
}

.range:active::-ms-thumb {
  border: none;
}

output {
  border: 1px solid #00933a;
  color: #333;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  padding: 0.4em 0.6em;
  border-radius: 3px;
}

.container--dark {
  background-color: #11181d;

  h6 {
    color: #ccc;
  }

  .range::-webkit-slider-runnable-track {
    background-color: #606670;
  }

  .range[disabled]::-webkit-slider-runnable-track {
    border: 1px solid #606670;
    background-color: transparent;
  }

  .range::-moz-range-track {
    background-color: #606670;
    border: none;
  }

  .range::-ms-fill-lower {
    background-color: #606670;
  }

  .range::-ms-fill-upper {
    background-color: #606670;
  }

  output {
    border: 1px solid #2b3039;
    color: #aaa;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    padding: 0.4em 0.6em;
    border-radius: 3px;
  }

  .range::-webkit-slider-thumb {
    background-color: #aaa;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .range[disabled]::-webkit-slider-thumb {
    background-color: transparent;
    border: 1px solid #606670;
    box-shadow: none;
  }

  .range::-moz-range-thumb {
    background-color: #aaa;
  }

  .range::-ms-thumb {
    background-color: #aaa;
  }
}

@media (max-width: 767px) {
  .filterpopup {
    .custom-popup {
      .popup {
        .filter-field {
          padding-bottom: 5px;
          margin-bottom: 5px;
          select {
            padding: 6px 15px;
          }
        }
      }
    }
  }
}
