@import "./theme";

%heading {
  font-size: 2.5rem;
  line-height: 3.9rem;  
  margin-bottom: 3.75rem;  
  font-weight: $boldest;
  color: $primary-black;
  // TODO
  // font-family: "Eras ITC";
  span {
    
  }
}

%container1080 {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

%container500 {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

%error {
  font-size: 10px;
  margin-top: 5px;
  color: $danger;
}

%input-error {
  border: 1px solid $danger !important;
}

%auth-field {
  margin-bottom: 20px;
  label {
    width: 100%;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .error {
    @extend %error;
  }
}

// background gradient
%common-gradient{
  background: -webkit-linear-gradient(top, rgba(244, 244, 244, 0.96) 0%, rgba(191, 191, 190, 0.91) 21%, rgba(126, 126, 126, 0.82) 41%, rgba(100, 100, 100, 0.94) 59%, rgba(104, 104, 104, 0.94) 72%, rgba(198, 199, 200, 0.94) 92%);
  background: -o-linear-gradient(top, rgba(244, 244, 244, 0.96) 0%, rgba(191, 191, 190, 0.91) 21%, rgba(126, 126, 126, 0.82) 41%, rgba(100, 100, 100, 0.94) 59%, rgba(104, 104, 104, 0.94) 72%, rgba(198, 199, 200, 0.94) 92%);
  background: -ms-linear-gradient(top, rgba(244, 244, 244, 0.96) 0%, rgba(191, 191, 190, 0.91) 21%, rgba(126, 126, 126, 0.82) 41%, rgba(100, 100, 100, 0.94) 59%, rgba(104, 104, 104, 0.94) 72%, rgba(198, 199, 200, 0.94) 92%);
  background: -moz-linear-gradient(top, rgba(244, 244, 244, 0.96) 0%, rgba(191, 191, 190, 0.91) 21%, rgba(126, 126, 126, 0.82) 41%, rgba(100, 100, 100, 0.94) 59%, rgba(104, 104, 104, 0.94) 72%, rgba(198, 199, 200, 0.94) 92%);
  background: linear-gradient(to bottom, rgba(244, 244, 244, 0.96) 0%, rgba(191, 191, 190, 0.91) 21%, rgba(126, 126, 126, 0.82) 41%, rgba(100, 100, 100, 0.94) 59%, rgba(104, 104, 104, 0.94) 72%, rgba(198, 199, 200, 0.94) 92%);
}

%common-gradient-image{
  background: url('./../Assets/Images/common-banner/gradients_bg.png') no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
}

%all-price{
  width: 90%;
  margin: 0 auto;
  border: 2px solid $primary-black;
  border-radius: 10px;
  overflow: hidden;
  .heading-price{
    font-size: 1.42rem;
    display: flex;
    .cap,.price{
      padding: 10px 8px;
    }
    .cap{
      font-weight: 500;
      border-bottom: 2px solid $white;
      color: $white;
      background-color: $primary-black;
      width: calc(100% / 2);
      display: block;
      text-align: right;
      &.first{
        background-color: $green ;
      }
    }
    .price{
      font-weight: 700;
      width: calc(100% / 2);
      border-bottom: 2px solid $primary-black;
      color: $primary-black;
    }
    &:first-child{
      .cap{
        background-color: $green;
      }
    }
    &:last-child{
      .cap,.price{
        border:0;
      }
    }

  }
}

%list-card {
  margin-bottom: 30px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  background-color: $white;
  .avatar {
    width: 100%;

    .image-card {
      height: 258px;
      max-width: 100%;
      overflow: hidden;
      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
        position: relative;

      }
    }
    .carousel-wrapper-class {
      position: relative;
      overflow: hidden;
      scroll-behavior: smooth;
      .other-images {        
        padding: 6px;
        background-color: $primary-black;
        // justify-content: center;
        overflow-x: auto;
        min-height: 51px;
        /* Let's get this party started */
        &::-webkit-scrollbar {
          width: 12px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          -webkit-border-radius: 10px;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 10px;
          border-radius: 10px;
          background: $primary-black;
          // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        }
        &::-webkit-scrollbar-thumb:window-inactive {
          background: $primary-black;
        }
        .other-image {
          margin: 0 2px;
          width: 52px;
          height: 39px;          
          img {
            width: 52px;
            height: 39px;
            object-fit: cover;
            cursor: pointer;
            border: 2px solid $primary-black;
            &.active {
              border: 2px solid $white;
            }
          }
        }    
        .no-photo{
          align-items: center;
          margin: auto;    
          font-size: 15px;
          color: white;
        }  
        .arrow
        {
          &.disable-next-prev{
            .arrow-left, .arrow-right{
              cursor: not-allowed;
              img{
                filter: grayscale(1);
              }
            }
          }
          .arrow-left, .arrow-right{
            position: absolute;
            top: 50%;
          // z-index: 999;
            transform: translateY(-50%);
            cursor: pointer;
            // color: #ffffff;
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
          }

          .arrow-left {
            left: 10px;
            img{
              transform: rotate(180deg);
            }
          }

          .arrow-right {
            right: 10px;
          }
        }   
      }   
    }
  }
  .card-body {
    padding: 15px 15px 15px 15px;
    ul.tab {
      margin-bottom: 1rem;
      justify-content: center;
      li {
        padding: 3px 6px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        font-weight: 700;
        margin: 0 10px;
        &.title {
          font-size: 18px;
          text-transform: uppercase;
          color: $primary-black;
          border: 4px solid #000000;
          background-color: #edb92d;
          border-radius: 6px;
          line-height: 100%;
          letter-spacing: 1.42px;
          width: 200px;
          display: flex;
          justify-content: center;
        }
        &.location {
          border: 4px solid $primary-black;
          background-color: $primary-black;
          font-size: 13px;
          color: $white;
          min-width: 130px;
          height: 40px;
          .icon {
            width: 10px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .card-heading {
    font-size: 24px;
    margin-bottom: 10px;
    color: $primary-black;
    line-height: 24px;
    min-height:24px;
    text-align: center;
  }
  .card-sub-heading{    
    margin-bottom: 10px;
    min-height: 27px;
    color: $primary-black;    
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;    
    overflow: hidden;
  }
  
  ul.feature {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    li {
      font-size: 13px !important;
      color: #797979;
      font-weight: $tiny-bold;
      padding-right: 20px;
      position: relative;
      &::after {
        position: absolute;
        right: 7px;
        top: 7px;
        content: "";
        height: 4px;
        width: 4px;
        background-color: #a1a1a1;
        border-radius: 50%;
      }
      &:last-child {
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }

  .guid-price {
    color: $secondary-black;
    font-size: 12px;
    font-weight: $boldest;
    padding-left: 15px;
    position: relative;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
    &:after {
      top: 5px;
      left: 0;
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      border-radius: 50%;
      background-color: #00bc80;
    }
  }
}